import { getParams } from '@/utils/auth'

export default [
  {
    path: '/apply',
    name: 'apply',
    component: () => import('@/views/admission/apply/index.vue'),
    meta: {
      layout: 'admission',
      resource: 'Auth',
      action: 'read',
      public: true,
      admission: true,
    },
    children: [
      {
        path: '',
        name: 'apply',
        component: () => import('@/views/admission/apply/components/apply.vue'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
      },
      {
        path: '/apply/:type',
        name: 'apply-types',
        component: () => import('@/views/admission/apply/components/types.vue'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
      },
      {
        path: '/apply/:type/:id',
        name: 'apply-form',
        component: () => import('@/views/admission/apply/form.vue'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
        children: [
          {
            path: '/apply/:type/:id/:success',
            name: 'apply-success',
            component: () => import('@/views/admission/apply/components/success.vue'),
            meta: {
              layout: 'admission',
              resource: 'Auth',
              action: 'read',
              public: true,
              admission: true,
            },
          },
        ],
      },
    ],
  },

  {
    path: '/apply-staff',
    name: 'apply-staff',
    component: () => import('@/views/admission/apply/index.vue'),
    meta: {
      layout: 'admission',
      resource: 'Auth',
      action: 'read',
      public: true,
      admission: true,
    },
    children: [
      {
        path: '',
        name: 'apply-staff',
        component: () => import('@/views/admission/apply/components/apply.vue'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
      },
      {
        path: '/apply-staff/:type',
        name: 'apply-staff-types',
        component: () => import('@/views/admission/apply/components/types.vue'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
      },
      {
        path: '/apply-staff/:type/:id',
        name: 'apply-staff-form',
        component: () => import('@/views/admission/apply/form.vue'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
        children: [
          {
            path: '/apply-staff/:type/:id/:success',
            name: 'apply-staff-success',
            component: () => import('@/views/admission/apply/components/success.vue'),
            meta: {
              layout: 'admission',
              resource: 'Auth',
              action: 'read',
              public: true,
              admission: true,
            },
          },
        ],
      },
    ],
  },

  {
    // // redirect: to => {
    // //     // the function receives the target route as the argument
    // //     // we return a redirect path/location here.
    // //         return { path: '/admissionForm/:type/:id/:secretKey/nationality'}
    // //   },
    // beforeEnter(route) {
    //     return {
    //         path: route.path,
    //         params: {
    //             ...route.params,
    //         }
    //     }
    // },
    name: 'admissionForm',
    path: '/admissionForm/:type/:id/:secretKey/:name?',
    redirect: { path: '/admissionForm/:type/:id/:secretKey/nationality' },
    permission: 'public',
    async beforeEnter(to, from, next) {
      await getParams('admission')
      next()
    },
    component: () => import('@/views/admission/applicant_information/index.vue'),
    meta: {
      pageTitle: 'admissionForm', breadcrumb: [{ text: 'admissionForm', active: true }],
    },
    children: [
      {
        path: '/admissionForm/:type/:id/:secretKey/name:nationality',
        name: 'admissionForms',
        permission: 'public',
        component: () => import('@/views/admission/applicant_information/form.vue'),
        meta: {
          pageTitle: 'admissionForm', breadcrumb: [{ text: 'admissionForm' }],
        },
      },
      {
        path: '/admissionForm/:type/:id/:secretKey/:name',
        name: 'applicant-information-form',
        permission: 'public',
        component: () => import('@/views/admission/applicant_information/form.vue'),
        meta: {
          pageTitle: 'admissionForm', breadcrumb: [{ text: 'admissionForm' }],
        },
        children: [

        ],
      },
    ],
  },
  {
    path: '/applyForm/success/:type/:msg',
    name: 'applyForm-success',
    permission: 'public',
    component: () => import('@/views/admission/applicant_information/components/success.vue'),
    meta: {
    },
  },
]
