import Resource from '../../resource';
import request from '../../../utils/request';

class preference extends Resource {
    constructor() {
        super('preferences');
    }
    preferencesTree() {
      
        return request({
          url: `/preferences/preferences_tree`,
          method: 'post',
        })
      }
    
      savePreferencesTree(payload) {
        console.log("payload",payload.sortedPreferences[0].preference_id)

        if (!payload.sortedPreferences[0].preference_id) {
          return
        }
        return request({
          url: `/preferences/preferences_tree/save/${payload.sortedPreferences[0].preference_id}`,
          method: 'post',
          data: payload,
        })
      }
    
}
export { preference as default };