import preference from '@/api/student/preferences/preferences';

const preferences = new preference();

const preferenceModule= {
  namespaced: true,
  state: {
    item: {},
    items: [],
    total: 0,
    lookups: null,
    load: false,
    currentId: '',
   
  },
  getters: {
    items(state) {
      return state.items;
    },
    item(state) {
      return state.item;
    },
    load(state) {
      return state.load;
    },
    total(state) {
      return state.total;
    },
    status(state) {
      return state.item.removed;
    },
    lookups(state) {
      return state.lookups;
    },
    currentId(state) {
      return state.currentId
    },
    gradesVisibility(state) {
      return state.gradesVisibility
    },
  },
  mutations: {
    SET_ITEMS: (state, items) => {
      state.items = items;
    },
    SET_TOTAL_ITEMS: (state, total) => {
      state.total = total;
    },
    SET_ITEMS_LOAD: (state, load) => {
      state.load = load;
    },
    SET_ITEM: (state, item) => {
      state.item = item;
    },
    SET_LOOKUPS(state, lookups) {
      state.lookups = lookups;
    },
    SET_CURRENT_ID(state, currentId) {
      state.currentId = currentId
    },
    SET_GRADES_VISIBILITY(state, gradesVisibility) {
      state.gradesVisibility = gradesVisibility
    },
  },
  actions: {
    preferences({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('app/SET_GENERAL_LOAD', {link: 'preferences', data: true}, {root: true});
        preferences.list(payload.query).then(response => {
          commit('SET_ITEMS', response.data);
          commit('SET_TOTAL_ITEMS', response?.meta?.count);
          commit('app/SET_GENERAL_TOTAL', {link: 'preferences', data: response?.meta?.count}, {root: true});
          commit('app/SET_GENERAL_LOAD', {link: 'preferences', data: false}, {root: true});
          resolve(response);
        }).catch(error => {
          commit('app/SET_GENERAL_LOAD', {link: 'preferences', data: false}, {root: true});
          reject(error);
        });
      });
    },
    put({commit}, payload) {
      commit('app/UPDATE_LOAD', true, {root: true});
      return new Promise((resolve, reject) => {
        preference
          .put(payload.id, payload.query)
          .then(response => {
            commit('app/UPDATE_LOAD', false, {root: true});
            // router.push({ name: 'preference', params: { id: response.data.id } })
            resolve(response);
          })
          .catch(error => {
            commit('app/UPDATE_LOAD', false, {root: true});
            reject(error);
          });
      });
    },


    get({commit}, id) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        preferences.get(id)
          .then(response => {
            commit('SET_GRADES_VISIBILITY', response.meta)
            commit('SET_ITEM', response.data);
            if (response && response.data && typeof response.data == 'object') {
              commit('app/UPDATE_PAGE_DETAILS', response.data, {root: true});
            }
            commit('SET_ITEMS_LOAD', false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
 
  
    preferencesTree({commit}) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        preferences.preferencesTree().then(response => {
          commit('SET_ITEMS_LOAD', false);
          resolve(response);
        });
      });
    },
    savePreferencesTree({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        preferences.savePreferencesTree(payload).then(response => {
          commit('SET_ITEMS_LOAD', false);
          resolve(response);
        });
      });
    },

  
  },
};

export default preferenceModule;
