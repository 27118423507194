import service from "@/utils/request";
let permission;
service.get("university/data").then((res) => {
  permission = res.data.find((x) => x.name === "attendance_with_qr_code").value;
  if (!permission) {
    permission = "no-access";
    return;
  }
  permission = "public";
});

export default [
  {
    path: "/attendance",
    name: "attendance",
    permission,
    component: () => import("@/views/attendance/index"),
    meta: {
      pageTitle: "Global.Attendance",
      breadcrumb: [{ text: "Global.Attendance", active: true }],
    },
  },
];
