import { $themeBreakpoints } from "@themeConfig";
import {
  getUserLiveData,
  getLookups,
  getCurrentTerms,
  getUniversityData,
} from "@/api/system/global";

export default {
  namespaced: true,
  state: {
    universityData: null,
    windowWidth: 0,
    shallShowOverlay: false,
    load: false,
    moreDetails: [],
    pageDetails: null,
    path: [],
    uploadProgress: false,
    errors: null,
    lookups: null,
    filter: null,
    tabsFilter: [],
    currentPaginationPage: 1,
    userLiveData: {
      chat_notification_count: 0,
    },
  },
  getters: {
    universityData: (state) => {
      if (state.universityData) {
        const object = state.universityData.reduce(
          (obj, item) =>
            Object.assign(obj, {
              [item.name]: item.systemOption ?? item.value,
            }),
          {},
        );
        return object;
      }
    },
    lookups: (state) => state.lookups,
    filter: (state) => state.filter,
    load: (state) => state.load,
    pageDetails: (state) => state.pageDetails,
    path: (state) => state.path,
    moreDetails: (state) => JSON.parse(JSON.stringify(state.moreDetails)),
    uploadProgress: (state) => state.uploadProgress,
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) {
        return "xl";
      }
      if (windowWidth >= $themeBreakpoints.lg) {
        return "lg";
      }
      if (windowWidth >= $themeBreakpoints.md) {
        return "md";
      }
      if (windowWidth >= $themeBreakpoints.sm) {
        return "sm";
      }
      return "xs";
    },
    tabsFilter: (state) => state.tabsFilter,
  },
  mutations: {
    SET_UNIVERSITY_DATA(state, val) {
      state.universityData = val;
    },
    UPDATE_PAGE_DETAILS(state, val) {
      state.pageDetails = val;
    },
    SET_FILTER(state, val) {
      state.filter = val;
    },
    SET_PATH(state, val) {
      state.path = val;
    },
    BACK(state) {
      state.moreDetails.pop();
    },
    UPDATE_MORE_DETAILS(state, val) {
      state.moreDetails.push(val);
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    ERRORS(state, val) {
      state.errors = val;
    },
    UPDATE_LOAD(state, val) {
      state.load = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },
    SET_UPLOAD_PROGRESS(state, uploadProgress) {
      state.uploadProgress = uploadProgress;
    },
    SET_LOOKUPS(state, data) {
      state.lookups = data;
    },
    SET_TABS_FILTER(state, data) {
      state.tabsFilter[`${data.index}`] = data.filter;
    },
    RESET_TABS_FILTER(state) {
      state.tabsFilter = [];
    },
    SET_CURRENT_PAGE(state, val) {
      state.currentPaginationPage = val;
    },
    SET_USER_LIVE_DATA(state, val) {
      state.userLiveData = val;
    },
  },
  actions: {
    GET_UNIVERSITY_DATA({ commit }, params) {
      return new Promise((resolve, reject) => {
        getUniversityData(params).then((response) => {
          commit("SET_UNIVERSITY_DATA", response.data);
          resolve(response);
        });
      });
    },
    GET_LOOKUPS({ commit }, lookupModules) {
      return new Promise((resolve, reject) => {
        getLookups(lookupModules).then((response) => {
          commit("SET_LOOKUPS", response.success);
          resolve(response);
        });
      });
    },
    updateTabsFilter({ commit }, data) {
      commit("SET_TABS_FILTER", data);
    },
    resetTabsFilter({ commit }) {
      commit("RESET_TABS_FILTER");
    },
    GET_USER_LIVE_DATA({ commit }, params) {
      return new Promise((resolve, reject) => {
        getUserLiveData(params).then((response) => {
          commit("SET_USER_LIVE_DATA", response.data);
        });
      });
    },
    getTerms({ commit }, callback) {
      return new Promise((resolve, reject) => {
        getCurrentTerms()
          .then((res) => {
            resolve({
              current_admission_term: res.data.current_admission_term_UG,
              current_admission_term_PG: res.data.current_admission_term_PG,
              current_control_term: res.data.current_control_term,
              current_exam_term: res.data.current_exam_term,
              current_term: res.data.current_term,
              current_transfer_term: null,
            });
            callback();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
