export default [
  {
    path: '/overview',
    name: 'dashboard',
    component: () => import('@/views/dashboard/index.vue'),
    meta: {
      requiresAuth: true,
      permission: 'admin_system',
    },
  },
]
